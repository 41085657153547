import { Head } from '@inertiajs/react';
import Header from '@/Components/Built/Website/Header';
import Footer from '@/Components/Built/Website/Footer';
import { PageProps } from '@/types';
import LoginForm from '@/Components/Built/Auth/LoginForm';
import { Calendar, Lock, Dog, Tag, PhoneCall, Clock, Map, Star, Shield } from 'lucide-react';
import Banner from '@/Components/Built/Website/Banner';

export default function Login({ auth, field }: PageProps<{ field: string }>) {
    const reasons = [
        { text: "Easy and more streamlined booking process", icon: Calendar },
        { text: "Manage your dogs information for a better experience", icon: Dog },
        { text: "Exclusive member discounts", icon: Tag },
        { text: "Improved customer support", icon: PhoneCall },
        { text: "Ability to edit and ammend your bookings", icon: Clock },
        { text: "Loyalty rewards program", icon: Star }
    ];

    return (
        <>
            <Head title="Login" />
            <Banner />
            <Header />
            <main className="min-h-screen bg-gray-100">
                <div className="container mx-auto px-4 py-12">
                    <div className="flex flex-col md:flex-row gap-12 items-start justify-center">
                        <div className="w-full md:w-1/2 max-w-md">
                            <h2 className="text-2xl font-bold mb-6 text-center md:text-left">Login to your Walkies Account</h2>
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <LoginForm />
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 max-w-md">
                            <h3 className="text-2xl font-semibold mb-6">Reasons to Sign Up</h3>
                            <div className="bg-white p-6 rounded-lg shadow-md">
                                <ul className="space-y-4">
                                    {reasons.map((reason, index) => (
                                        <li key={index} className="flex items-center space-x-3">
                                            <reason.icon className="h-6 w-6 text-brand" />
                                            <span>{reason.text}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}
