import React, { useState } from 'react';
import { Eye, EyeOff } from 'lucide-react';
import { Input } from "@/Components/ui/input";
import { Button } from "@/Components/ui/button";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "@/Components/ui/card";
import ApplicationLogo from '@/Components/ApplicationLogo';
import { useForm } from '@inertiajs/react';
import { useRoute } from 'ziggy-js';

const LoginForm: React.FC = () => {
    const route = useRoute();
    const [showPassword, setShowPassword] = useState(false);
    const [isGuestLogin, setIsGuestLogin] = useState(false);

    const { data, setData, post, processing, errors } = useForm({
        email: '',
        password: '',
        remember: false,
    });

    const { data: guestData, setData: setGuestData, post: postGuest, processing: processingGuest, errors: guestErrors } = useForm({
        email: '',
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        post(route('login'), {
            preserveState: true,
        });
    };

    const handleGuestSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        postGuest(route('guest.send-login-link'), {
            preserveState: true,
        });
    };

    const toggleGuestLogin = () => {
        setIsGuestLogin(!isGuestLogin);
    };

    return (
        <>
            <div className="col-span-2 bg-dark text-white p-4 mb-4">
                Returning Customer? If you had an account on our old website. You'll have to make a new account on our <a href='/register' className='underline'>Registration Page.</a>.
            </div>
            <p className='mb-4'>{isGuestLogin
                ? 'Enter your email to receive a guest login link.'
                : 'Login to your walkies account and manage your bookings for walkies settings.'}
            </p>
            {isGuestLogin ? (
                <form onSubmit={handleGuestSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <label htmlFor="guestEmail" className="text-sm font-medium">
                            Email Address
                        </label>
                        <Input
                            id="guestEmail"
                            type="email"
                            placeholder="Enter your email"
                            value={guestData.email}
                            onChange={(e) => setGuestData('email', e.target.value)}
                            required
                        />
                        {guestErrors.email && <div className="text-red-500 text-sm">{guestErrors.email}</div>}
                    </div>
                    <Button type="submit" className="w-full" disabled={processingGuest}>
                        {processingGuest ? 'Sending link...' : 'Send Login Link'}
                    </Button>
                </form>
            ) : (
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                        <label htmlFor="email" className="text-sm font-medium">
                            Email Address
                        </label>
                        <Input
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            value={data.email}
                            onChange={(e) => setData('email', e.target.value)}
                            required
                        />
                        {errors.email && <div className="text-red-500 text-sm">{errors.email}</div>}
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="password" className="text-sm font-medium">
                            Password
                        </label>
                        <div className="relative">
                            <Input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter your password"
                                value={data.password}
                                onChange={(e) => setData('password', e.target.value)}
                                required
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute right-3 top-1/2 transform -translate-y-1/2"
                            >
                                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                            </button>
                        </div>
                        {errors.password && <div className="text-red-500 text-sm">{errors.password}</div>}
                    </div>
                    <div className="text-right">
                        <a href={route('password.request')} className="text-sm text-blue-600 hover:underline">
                            Forgotten Your Password?
                        </a>
                    </div>
                    <Button type="submit" className="w-full" disabled={processing}>
                        {processing ? 'Logging in...' : 'Login'}
                    </Button>
                </form>
            )}
            <hr className='border-gray-200 border w-full mt-2 mb-2' />
            <Button className="w-full mb-4 bg-brand text-white" onClick={() => window.location.href = route('register')}>
                Create an Account (Reccomended)
            </Button>
            <Button className="w-full bg-gray-200 text-black hover:bg-gray-300" onClick={toggleGuestLogin}>
                {isGuestLogin ? 'Back to Normal Login' : 'Login as Guest'}
            </Button>
        </>
    );
};

export default LoginForm;